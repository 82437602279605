import React from "react";

import { getDayMonthYearInterval } from "../../../helpers/functions";

import "./DepartmentInfo.css";

const DepartmentInfo = ({ department }) => {
    return department ? (
        <div className="department-info">
            <div
                className="department-name"
                style={{
                    color: department.is_special ? department.color_code : undefined,
                }}
            >
                {department.name}
            </div>
            <div className="department-description">
                Edition Virtuelle -{" "}
                <span className="department-duration">
                    {getDayMonthYearInterval(department.activation_date, department.desactivation_date)}
                </span>
            </div>
        </div>
    ) : null;
};

export default DepartmentInfo;
