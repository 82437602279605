import { GET_CONFERENCES_SUCCESS } from "./actionTypes";

const defaultState = {
    conferences: [],
    conferencesLoading: false,
};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CONFERENCES_SUCCESS: {
            return {
                ...state,
                conferences: payload,
            };
        }

        default:
    }
    return state;
};
