import React, { useState, useCallback, useEffect } from "react";
import { Modal, Typography, message } from "antd";
import { connect } from "react-redux";

import { getInterval } from "../../../helpers/functions";
import { registerConference } from "../../../api/conferences";
import { subscribeAndConnect, clearProfileMessages } from "../../../domains/profile/actions";

import ConferenceRegistrationForm from "./ConferenceRegistrationForm";

import "./ConferenceRegistrationModal.css";

const { Title } = Typography;

const ConferenceRegistrationModal = ({
    conference,
    onCancel,
    onRegisterSuccess,
    profile,
    profileErrorMessages,
    subscribeAndConnect,
    clearProfileMessages,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const isAnonymousVisitor = useCallback(profile => {
        return profile && profile.data && !!profile.data.unknown_token;
    }, []);

    useEffect(() => {
        if (profileErrorMessages && profileErrorMessages.length > 0) {
            profileErrorMessages.map(m => {
                message.error(m);
            });
            clearProfileMessages();
        }
    }, [profileErrorMessages, clearProfileMessages]);

    return (
        <Modal
            className="conference-registration-modal"
            title={`Inscrivez vous${conference.is_paid ? "" : " gratuitement"} à la Conférence`}
            width={450}
            visible={true}
            centered
            onCancel={() => {
                if (!isLoading) {
                    onCancel();
                }
            }}
            footer={null}
        >
            <div
                className="conference-image"
                style={{
                    backgroundImage: `url(${conference.image_url})`,
                }}
            />
            <div className="conference-details">
                <Title level={3} className="conference-title">
                    {conference.title}
                </Title>
                <div className="conference-author">{conference.author}</div>
                <div className="conference-time">{getInterval(conference.start_date, conference.end_date, true)}</div>
            </div>
            <ConferenceRegistrationForm
                profile={profile}
                conference={conference}
                onRegister={async profileData => {
                    setIsLoading(true);
                    const register = async () => {
                        const onFailure = error => {
                            // TODO: @EH remove
                            console.log({ error });
                            message.error("Impossible de vous authentifier.");
                        };
                        await registerConference(conference.id, onRegisterSuccess, onFailure);
                    };
                    if (isAnonymousVisitor(profile)) {
                        try {
                            await subscribeAndConnect(profileData, false);
                            message.info("Votre compte a bien été créé.");
                            await register();
                        } catch (e) {
                            setIsLoading(false);
                        }
                    } else {
                        await register();
                    }
                }}
                isLoading={isLoading}
            />
        </Modal>
    );
};

export default connect(
    state => ({
        profile: state.profile.profile,
        profileErrorMessages: state.profile.message,
    }),
    {
        subscribeAndConnect,
        clearProfileMessages,
    },
)(ConferenceRegistrationModal);
