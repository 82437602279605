import React, { useState } from "react";
import { Button, Tag, Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

import { getInterval, getDayOfMonth, getMonth, getYear } from "../../../helpers/functions";
import ConferenceRegistrationModal from "./ConferenceRegistrationModal";
import ConferencePaywallModal from "./ConferencePaywallModal";
import ConferenceZoomDetailsModal from "./ConferenceZoomDetailsModal";

import "./Conference.css";

const { Title } = Typography;

const Conference = ({ conference, department }) => {
    const [isRegistrationModalVisible, setIsRegistrationModalVisible] = useState(false);
    const [isPaywallModalVisible, setIsPaywallModalVisible] = useState(false);
    const [isZoomDetailsModalVisible, setIsZoomDetailsModalVisible] = useState(false);

    return (
        <>
            <div className="conference">
                <div className="conference-date">
                    <div className="conference-date-day-month">
                        <Title level={3} className="conference-date-day">
                            {getDayOfMonth(conference.start_date)}
                        </Title>
                        <span className="conference-date-month">{getMonth(conference.start_date)}</span>
                    </div>
                    <div className="conference-date-year">{getYear(conference.start_date)}</div>
                </div>
                <div className="conference-meta">
                    <div
                        className="conference-cover"
                        style={{
                            backgroundImage: `url(${conference.image_url})`,
                        }}
                    >
                        <div className={`payment-info payment-info-${conference.is_paid ? "paid" : "free"}`}>
                            {conference.is_paid ? "Payant" : "Gratuit"}
                        </div>
                    </div>
                    <div className="conference-details">
                        {conference.category && <Tag className="conference-category">{conference.category}</Tag>}
                        <div className="conference-title-container">
                            <span className="conference-author">{conference.author}</span>
                            <Title level={3} className="conference-title">
                                {conference.title}
                            </Title>
                        </div>
                        <div className="conference-description">
                            <div className="conference-description-paragraph">{conference.description}</div>
                            <div className="conference-time">
                                <ClockCircleOutlined className="conference-time-icon" />
                                <p className="conference-time-paragarph">
                                    {getInterval(conference.start_date, conference.end_date)}
                                </p>
                            </div>
                        </div>

                        <div className="conference-btn">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setIsRegistrationModalVisible(true);
                                }}
                            >
                                Inscription
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {isRegistrationModalVisible && (
                <ConferenceRegistrationModal
                    conference={conference}
                    onRegisterSuccess={() => {
                        setIsRegistrationModalVisible(false);
                        if (conference.is_paid) {
                            setIsPaywallModalVisible(true);
                        } else {
                            setIsZoomDetailsModalVisible(true);
                        }
                    }}
                    onCancel={() => {
                        setIsRegistrationModalVisible(false);
                    }}
                />
            )}
            {isZoomDetailsModalVisible && (
                <ConferenceZoomDetailsModal
                    conference={conference}
                    department={department}
                    onCancel={() => {
                        setIsZoomDetailsModalVisible(false);
                    }}
                />
            )}
            {isPaywallModalVisible && (
                <ConferencePaywallModal
                    conference={conference}
                    paymentType={"live_payment"}
                    department={department}
                    onCancel={() => {
                        setIsPaywallModalVisible(false);
                    }}
                />
            )}
        </>
    );
};

export default Conference;
