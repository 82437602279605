import { apiWithAuth, apiWithAuthWithResponseInterceptors } from "../helpers/http";

export const getConferences = departmentId => apiWithAuth(`conferences/department/${departmentId}`);

export const getConferenceDetails = conferenceId =>
    apiWithAuth(`conferences/${conferenceId}/details`);

export const getConferenceReplay = (conferenceId, onSuccess, onFailure) =>
    apiWithAuthWithResponseInterceptors(
        `conferences/${conferenceId}/replay`,
        {},
        {},
        onSuccess,
        onFailure
    );

export const registerConference = (conferenceId, onSuccess, onFailure) =>
    apiWithAuthWithResponseInterceptors(
        `conferences/${conferenceId}/register`,
        {},
        {
            method: "POST",
        },
        onSuccess,
        onFailure
    );
