import React, { useEffect, useState } from "react";
import { Button, Modal, Typography, Icon } from "antd";

import { getInterval } from "../../../helpers/functions";

import { getConferenceDetails } from "../../../api/conferences";

import "./ConferenceZoomDetailsModal.css";

const { Title } = Typography;

const ConferenceZoomDetailsModal = ({ conference, department, onCancel }) => {
    const [zoomId, setZoomId] = useState();
    const [zoomPassword, setZoomPassword] = useState();
    const [zoomLink, setZoomLink] = useState();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        const fetchConferenceDetails = async () => {
            const { zoom_id, zoom_password, zoom_link } = await getConferenceDetails(conference.id);
            setZoomId(zoom_id);
            setZoomPassword(zoom_password);
            setZoomLink(zoom_link);
        };
        if (!conference.is_paid) {
            fetchConferenceDetails()
                .then(() => {
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            // TODO: @EH
            setIsLoading(false);
        }

        return () => {
            setZoomId(undefined);
            setZoomPassword(undefined);
            setZoomLink(undefined);
        };
    }, []);

    return (
        <Modal
            className="conference-zoom-details-modal"
            title={null}
            visible={true}
            centered
            footer={null}
            onCancel={onCancel}
        >
            {isLoading && <Icon type="loading" />}
            {!isLoading && (
                <>
                    <div className="department-image">
                        <img src={department.url} alt={department.name} />
                    </div>
                    <div className="department-invitation-text">Vous invite à une réunion Zoom planifiée</div>
                    <div className="conference-details">
                        <Title level={3} className="conference-title">
                            {conference.title}
                        </Title>
                        <div className="conference-author">{conference.author}</div>
                        <div className="conference-time">
                            {getInterval(conference.start_date, conference.end_date, true)}
                        </div>

                        <div className="conference-zoom-id">
                            ID de réunion: <span>{zoomId}</span>
                        </div>
                        <div className="conference-zoom-password">
                            Code secret: <span>{zoomPassword}</span>
                        </div>
                    </div>
                    <div className="actions">
                        <Button
                            type="primary"
                            disabled={isLoading}
                            onClick={() => {
                                window.open(zoomLink, "_blank");
                            }}
                        >
                            Participez à la conférence sur ZOOM.US
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default ConferenceZoomDetailsModal;
