import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import { message } from "antd";
import { getDepartmentAuthorization } from "../../../domains/stands/actions";
import LoginModal from "../../page/login/LoginModal";
import history from "../../../helpers/history";

function DepartmentAuth({ profile, department, getDepartmentAuthorization }) {
    const [isVisible, setIsVisible] = useState(profile === undefined && department && department.is_special);

    const isAnonymousVisitor = useCallback(profile => {
        return profile && profile.data && !!profile.data.unknown_token;
    }, []);

    useEffect(() => {
        if (department && department.is_special) {
            getDepartmentAuthorization(department.id).then(isAuthorized => {
                setIsVisible(!isAuthorized);

                const departmentHomePage = `/departements/${department.code}`;

                if (!isAnonymousVisitor(profile) && !isAuthorized) {
                    message.error("Connexion impossible.");
                    history.push(`/`);
                } else if (!isAuthorized && history.location.pathname !== departmentHomePage) {
                    history.push(departmentHomePage);
                }
            });
        }
    }, [profile, department]);

    return (
        <Modal visible={isVisible} closable={false} centered footer={null}>
            <LoginModal department={department} isRegisterDisabled={true} />
        </Modal>
    );
}
export default connect(
    state => ({
        profile: state.profile.profile,
    }),
    {
        getDepartmentAuthorization,
    },
)(DepartmentAuth);
