import React, { useState } from "react";
import { Typography } from "antd";
import { getInterval } from "../../../helpers/functions";

import "./ConferenceReplay.css";
import ConferenceReplayModal from "./ConferenceReplayModal";
import ConferencePaywallModal from "./ConferencePaywallModal";

const { Title } = Typography;

const ConferenceReplay = ({ conference, department }) => {
    const [isReplayModalVisible, setIsReplayModalVisible] = useState(false);
    const [isReplayPaywallModalVisible, setIsReplayPaywallModalVisible] = useState(false);

    return (
        <>
            <div
                className="conference-replay"
                onClick={() => {
                    if (conference.is_paid) {
                        setIsReplayPaywallModalVisible(true);
                    } else {
                        setIsReplayModalVisible(true);
                    }
                }}
            >
                <div
                    className="conference-replay-img"
                    style={{
                        backgroundImage: `url(${conference.image_url})`,
                    }}
                />
                <div className="conference-replay-details">
                    <div
                        className="conference-replay-category"
                        style={{
                            color: department && department.is_special ? department.color_code : undefined,
                        }}
                    >
                        {conference.category}
                    </div>
                    <div className="conference-replay-title">
                        <Title level={4}>{conference.title}</Title>
                    </div>
                    <div className="conference-replay-author">{conference.author}</div>
                    <div className="conference-replay-infos">
                        <div className={`conference-replay-${conference.is_paid ? "paid" : "free"}`}>
                            {conference.is_paid ? "Payante" : "Gratuite"}
                        </div>
                        <div className="conference-replay-time">
                            {getInterval(conference.start_date, conference.end_date, true)}
                        </div>
                    </div>
                </div>
            </div>
            {isReplayModalVisible && (
                <ConferenceReplayModal
                    conference={conference}
                    onCancel={() => {
                        setIsReplayModalVisible(false);
                    }}
                />
            )}
            {isReplayPaywallModalVisible && (
                <ConferencePaywallModal
                    conference={conference}
                    paymentType={"replay_payment"}
                    department={department}
                    onCancel={() => {
                        setIsReplayPaywallModalVisible(false);
                    }}
                />
            )}
        </>
    );
};

export default ConferenceReplay;
