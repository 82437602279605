import React, { useEffect, useState } from "react";

const WixIFrameHomePageContainer = ({}) => {
    return (
        <iframe
            src="https://communicationniceo.wixsite.com/my-site-1"
            style={{
                border: "none",
                height: "100%",
                width: "100%",
            }}
        ></iframe>
    );
};

export default WixIFrameHomePageContainer;
