import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { loadConferences } from "../../../domains/conferences/actions";
import { loadActiveDepartments } from "../../../domains/stands/actions";
import ConferenceReplaysList from "../../common/conference/ConferenceReplaysList";
import ConferencesList from "../../common/conference/ConferencesList";

import "./ConferencesPageContainer.css";

const { TabPane } = Tabs;

const ConferencesPage = ({ conferences, activeDepartments, match, location, loadConferences }) => {
    const [department, setDepartment] = useState(location && location.state && location.state.dept);
    const [backgroundImageUrl, setBackgroundImage] = useState((department && department.conferences_image_url) || "");

    useEffect(() => {
        loadActiveDepartments();
    }, []);

    useEffect(() => {
        if (activeDepartments && activeDepartments.length > 0 && match.params && match.params.departmentCode) {
            setDepartment(activeDepartments.find(d => d.code === match.params.departmentCode));
        }
    }, [activeDepartments, match.params]);

    useEffect(() => {
        if (department) {
            loadConferences(department.id);
            setBackgroundImage(department.conferences_image_url);
        }
    }, [department, loadConferences]);

    return (
        <div className="conferences-page-container">
            <div
                className="conferences-header"
                style={{
                    backgroundImage: `url(${backgroundImageUrl})`,
                }}
            >
                <div className="conferences-header-content">
                    {department && (
                        <Link to={`/departements/${department.code}`}>
                            <div className="department-image">
                                <img src={department.url} alt={department.name} />
                            </div>
                        </Link>
                    )}
                    <div className="conferences-title">{department && department.conferences_title}</div>
                </div>
            </div>
            <div className="conferences-tabs">
                <Tabs
                    defaultActiveKey="agenda"
                    animated={false}
                    tabBarStyle={{
                        backgroundColor: department && department.is_special ? department.color_code : "",
                    }}
                >
                    <TabPane tab="Agenda" key="agenda">
                        <ConferencesList conferences={conferences} department={department} onlyUpcoming />
                    </TabPane>
                    <TabPane tab="Live" key="live">
                        <ConferencesList conferences={conferences} department={department} onlyLive />
                    </TabPane>
                    <TabPane tab="Replay" key="replay">
                        <ConferenceReplaysList conferences={conferences} department={department} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    conferences: state.conferences.conferences,
    activeDepartments: state.stands.activeDepartments,
});

const mapDispatchToProps = {
    loadConferences,
    loadActiveDepartments,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConferencesPage);
