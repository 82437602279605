import React, { useEffect, useState } from "react";
import { Modal, Icon, message } from "antd";

import { get, isEmpty, trim } from "lodash";

import { getConferenceReplay } from "../../../api/conferences";

import "./ConferenceReplayModal.css";

const getYoutubeEmbed = id => {
    const width = 560;
    const height = 315;
    return `<iframe width="${width}" height="${height}" src="https://www.youtube.com/embed/${trim(id)}"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture" 
        allowfullscreen>
    </iframe>`;
};

const getDailyMotionEmbed = id => {
    return `<div style="position: relative;padding-bottom: 56.25%;height: 0;overflow: hidden;">
            <iframe style=" width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; overflow: hidden;"
                frameborder="0"
                type="text/html"
                src="https://www.dailymotion.com/embed/video/${trim(id)}?autoplay=1"
                width="100%"
                height="100%"
                allowfullscreen
                allow="autoplay"
                >
            </iframe>
    </div>`;
};

const getVimeoEmbed = id => {
    const width = 640;
    const height = 360;
    return `<iframe width="${width}" height="${height}" src="https://player.vimeo.com/video/${trim(id)}"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
    ></iframe>
  `;
};

const emptyEmbedMessage = `<div style="text-align: center;">Lien actuellement indisponible. Vueillez réessayer plus tard.</div>`;

const ConferenceReplayModal = ({ conference, onCancel }) => {
    const [replayLink, setReplayLink] = useState();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        const fetchConferenceReplay = async () => {
            const onFailure = error => {
                message.error(get(error, "response.data.message"));
                onCancel();
            };
            const onSuccess = response => {
                const { youtube_replay_id, dailymotion_replay_id, vimeo_replay_id } = response.data;
                if (!isEmpty(youtube_replay_id)) {
                    setReplayLink(getYoutubeEmbed(youtube_replay_id));
                } else if (!isEmpty(dailymotion_replay_id)) {
                    setReplayLink(getDailyMotionEmbed(dailymotion_replay_id));
                } else if (!isEmpty(vimeo_replay_id)) {
                    setReplayLink(getVimeoEmbed(vimeo_replay_id));
                } else {
                    setReplayLink(emptyEmbedMessage);
                }
            };
            await getConferenceReplay(conference.id, onSuccess, onFailure);
        };

        fetchConferenceReplay()
            .then(() => {
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });

        return () => {
            setReplayLink(undefined);
        };
    }, []);

    return (
        <Modal
            className="conference-replay-modal"
            title={null}
            visible={true}
            centered
            footer={null}
            onCancel={onCancel}
            width={800}
        >
            {isLoading && <Icon type="loading" />}
            {!isLoading && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: replayLink,
                    }}
                />
            )}
        </Modal>
    );
};

export default ConferenceReplayModal;
