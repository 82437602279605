import React, { useState, useCallback, useEffect } from "react";
import { Modal, Typography, message, Button } from "antd";
import { connect } from "react-redux";

import { getInterval } from "../../../helpers/functions";
import { registerConference } from "../../../api/conferences";

import "./ConferencePaywallModal.css";

const { Title } = Typography;

const ConferencePaywallModal = ({ conference, paymentType, profile, onCancel }) => {
    const isAnonymousVisitor = useCallback(profile => {
        return profile && profile.data && !!profile.data.unknown_token;
    }, []);

    return (
        <Modal
            className="conference-paywall-modal"
            title={`Réservez votre invitation pour la conférence`}
            visible={true}
            centered
            onCancel={onCancel}
            footer={null}
        >
            <div
                className="conference-image"
                style={{
                    backgroundImage: `url(${conference.image_url})`,
                }}
            />
            <div className="conference-details">
                <Title level={2} className="conference-title">
                    {conference.title}
                </Title>
                <div className="conference-author">{conference.author}</div>
                <div className="conference-time">{getInterval(conference.start_date, conference.end_date, true)}</div>
            </div>
            <div></div>
            <div
                dangerouslySetInnerHTML={{
                    __html: paymentType === "live_payment" ? conference.paypal_link : conference.replay_paypal_link,
                }}
            />
            <div className="footer-text">
                Vous serez redirigé vers la fenêtre des identifiants après le paiement de votre réservation
            </div>
        </Modal>
    );
};

export default connect(state => ({
    profile: state.profile.profile,
}))(ConferencePaywallModal);
