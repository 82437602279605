import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./ConferencesCta.css";

const ConferencesCta = ({ department }) => {
    return (
        <Link className="conferences-cta-wrapper" to={`/departements/${department.code}/conferences`}>
            <div
                className="conferences-cta"
                style={{
                    backgroundImage: `url(${department.conferences_image_url})`,
                }}
            >
                <span>{department.conferences_title_teaser}</span>
            </div>
        </Link>
    );
};

export default ConferencesCta;
