import { connect } from "react-redux";
import React, { useCallback } from "react";
import { login } from "../../../domains/profile/actions";
import LoginForm from "../../common/login/LoginFormContainer";
import LogoMafoire from "../../../assets/images/mafoire-logo.svg";
import "./LoginModal.css";
import { Link } from "react-router-dom";

const LoginModal = ({ profile, login, department, isRegisterDisabled, ...props }) => {
    const handleSubmit = useCallback(
        values => {
            login(values, `/departements/${department.code}`);
        },
        [login, department],
    );

    return (
        <div className="login-modal">
            {department && department.is_special ? (
                <Link to={`/departements/${department.code}`}>
                    <img className="logo" src={department.url} alt="" />
                </Link>
            ) : (
                <Link to="/">
                    <img className="logo" src={LogoMafoire} alt="Logo Mafoire.com" />
                </Link>
            )}
            <div className="title">Connexion</div>
            <LoginForm
                handleSubmit={handleSubmit}
                profile={profile}
                department={department}
                isRegisterDisabled={isRegisterDisabled}
            />
        </div>
    );
};

export default connect(
    state => ({
        profile: state.profile,
    }),
    {
        login,
    },
)(LoginModal);
