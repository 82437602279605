import React from "react";

import { chain } from "lodash";
import moment from "moment";

import ConferenceReplay from "./ConferenceReplay";
import DepartmentInfo from "../department/DepartmentInfo";

import "./ConferenceReplaysList.css";

const ConferenceReplaysList = ({ conferences, department }) => {
    const sortedConferences = chain(conferences)
        .filter(c => moment(c.end_date).isBefore(moment()))
        .sortBy(c => moment(c.start_date))
        .reverse()
        .value();

    return (
        <div className="conference-replays-wrapper">
            <DepartmentInfo department={department} />
            <div className="conference-replays-list">
                {sortedConferences.map((conference, conferenceIndex) => (
                    <ConferenceReplay key={conferenceIndex} conference={conference} department={department} />
                ))}
            </div>
        </div>
    );
};

export default ConferenceReplaysList;
