import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Form, Icon, Input } from "antd";
import { nanoid } from "nanoid";

import "./ConferenceRegistrationForm.css";

const FormItem = Form.Item;

const ConferenceRegistrationForm = ({ profile, form, onRegister, isLoading }) => {
    const isAnonymousVisitor = useCallback(profile => {
        return profile && profile.data && !!profile.data.unknown_token;
    }, []);

    const [hasAcceptedTermsService, setHasAcceptedTermsService] = useState(isAnonymousVisitor(profile) ? 0 : 1);

    const [isEditable, setIsEditable] = useState(isAnonymousVisitor(profile));

    useEffect(() => {
        setIsEditable(isAnonymousVisitor(profile));
    }, [profile, isAnonymousVisitor]);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            form.validateFields((err, values) => {
                if (err) return;
                if (isAnonymousVisitor(profile)) {
                    onRegister({
                        ...profile,
                        ...values,
                        password: nanoid(),
                        terms_service: hasAcceptedTermsService,
                    });
                } else {
                    onRegister({
                        ...profile,
                        ...values,
                    });
                }
            });
        },
        [form, profile, onRegister, isAnonymousVisitor],
    );

    const { getFieldDecorator } = form;

    return (
        <Form className="conference-registration-form" onSubmit={handleSubmit}>
            <FormItem>
                {getFieldDecorator("firstname", {
                    rules: [
                        {
                            required: true,
                            message: "Veuillez saisir cette information",
                        },
                    ],
                    initialValue: profile ? profile.firstname : "",
                })(<Input type="text" disabled={!isEditable} placeholder="Prénom" />)}
            </FormItem>
            <FormItem>
                {getFieldDecorator("lastname", {
                    rules: [
                        {
                            required: true,
                            message: "Veuillez saisir cette information",
                        },
                    ],
                    initialValue: profile ? profile.lastname : "",
                })(<Input type="text" disabled={!isEditable} placeholder="Nom" />)}
            </FormItem>
            <FormItem>
                {getFieldDecorator("email", {
                    rules: [
                        {
                            required: true,
                            type: "email",
                            message: "Veuillez saisir un e-mail valide",
                        },
                    ],
                    initialValue: profile ? profile.email : "",
                })(<Input type="text" disabled={!isEditable} placeholder="Votre email" />)}
            </FormItem>
            <FormItem>
                {getFieldDecorator("telephone", {
                    initialValue: profile ? profile.telephone : "",
                })(<Input type="tel" disabled={!isEditable} placeholder="Téléphone" />)}
            </FormItem>
            <FormItem>
                {getFieldDecorator("department", {
                    initialValue: profile ? profile.department : "",
                })(<Input type="text" disabled={!isEditable} placeholder="Code Postal" />)}
            </FormItem>
            {isAnonymousVisitor(profile) && (
                <Form.Item>
                    {getFieldDecorator("terms_service", {
                        valuePropName: "checked",
                        rules: [
                            {
                                required: true,
                                message:
                                    "Vous devez prendre connaissance des mentions légales et les cocher afin de créer votre compte",
                            },
                        ],
                    })(
                        <div className="terms-service-container">
                            <Checkbox
                                onChange={event => {
                                    const isChecked = event.target.checked;
                                    setHasAcceptedTermsService(isChecked ? 1 : 0);
                                }}
                            />
                            <div className="terms-service-label">
                                J'accepte les{" "}
                                <span>
                                    <a
                                        href="https://mafoire-medias.s3.eu-central-1.amazonaws.com/CONDITIONS+GENERALES.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        conditions générales d'utilisation
                                    </a>
                                </span>
                            </div>
                        </div>,
                    )}
                </Form.Item>
            )}
            <FormItem className="actions">
                <Button type="primary" htmlType="submit" disabled={isLoading || !hasAcceptedTermsService}>
                    Obtenez votre invitation
                    {isLoading && <Icon type="loading" />}
                </Button>
            </FormItem>
        </Form>
    );
};

export default Form.create()(ConferenceRegistrationForm);
