import React, { Component } from "react";
import { connect } from "react-redux";
import { loadMapStands } from "../../../domains/stands/actions";
import GoogleMapComponentWithMarker from "./GoogleMapComponentWithMarker";
import { getGmapCoordinates } from "../../../helpers/functions";
import history from "../../../helpers/history";
import StandInfosCard from "./map/StandInfosCard";
import "./StandsListGmap.css";

class StandsListGmap extends Component {
    state = {
        departmentCoordinates: null,
    };

    async componentDidMount() {
        if (this.props.activeDepartments) {
            const { params, activeDepartments, loadMapStands, stands, standsTheme, standsDepartment } = this.props;
            const currentDepartment = activeDepartments.find(dep => dep.code === params.department);

            if (!stands || standsDepartment !== currentDepartment.id || standsTheme !== params.themeId)
                await loadMapStands(currentDepartment.id, params.themeId);

            const departmentCode = parseInt(currentDepartment.code);
            const departmentName = currentDepartment.name;
            const region =
                departmentCode > 100 && !!currentDepartment.region_code ? currentDepartment.region_code : "France";

            const address = `${departmentName} ${region}`;

            const departmentCoordinates = await getGmapCoordinates(address);
            this.setState({
                departmentCoordinates,
            });
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.activeDepartments) {
            const { params, activeDepartments, loadMapStands, stands, standsTheme, standsDepartment } = this.props;
            const currentDepartment = activeDepartments.find(dep => dep.code === params.department);

            if (!stands || standsDepartment !== currentDepartment.id || standsTheme !== params.themeId) {
                await loadMapStands(currentDepartment.id, params.themeId);
                const departmentCode = parseInt(currentDepartment.code);
                const departmentName = currentDepartment.name;
                const region =
                    departmentCode > 100 && !!currentDepartment.region_code ? currentDepartment.region_code : "France";

                const address = `${departmentName} ${region}`;

                const departmentCoordinates = await getGmapCoordinates(address);

                this.setState({
                    departmentCoordinates,
                });
            }
        }
    }

    render() {
        const { stands, params, selectedStand, setSelectedStand } = this.props;
        const { departmentCoordinates } = this.state;

        return (
            <div className="stands-list-container-gmap">
                {departmentCoordinates && stands && (
                    <GoogleMapComponentWithMarker
                        googleMapApiKey={window.process.env.APP_GOOGLE_API_KEY}
                        stands={stands}
                        department={params.department}
                        themeId={params.themeId}
                        departmentCoordinates={departmentCoordinates}
                        setSelectedStand={setSelectedStand}
                    />
                )}
                {selectedStand && (
                    <StandInfosCard
                        stand={selectedStand}
                        goToStand={() =>
                            history.push(
                                `/departements/${params.department}/themes/${params.themeId}/stands/${selectedStand.id}/accueil`,
                            )
                        }
                        close={() => setSelectedStand(null)}
                    />
                )}
            </div>
        );
    }
}

export default connect(
    state => ({
        stands: state.stands.gmapStands,
        standsDepartment: state.stands.standsDepartment,
        standsTheme: state.stands.standsTheme,
    }),
    {
        loadMapStands,
    },
)(StandsListGmap);
