import React, { useCallback } from "react";

import Conference from "./Conference";
import { chain } from "lodash";
import moment from "moment";

import DepartmentInfo from "../department/DepartmentInfo";

import "./ConferencesList.css";

const ConferencesList = ({ conferences, department, onlyLive = false, onlyUpcoming = false }) => {
    const isLiveConference = useCallback(
        conference => moment(conference.start_date).isBefore(moment()) && moment(conference.end_date).isAfter(moment()),
        [],
    );

    const isFutureConference = useCallback(conference => moment(conference.start_date).isAfter(moment()), []);

    const conferencesByMonthYear = chain(conferences)
        .filter(c => (onlyLive ? isLiveConference(c) : onlyUpcoming ? isFutureConference(c) : true))
        .sortBy("start_date")
        .groupBy(c => moment(c.start_date).format("MMM YYYY"))
        .value();

    return (
        <div className="conferences-list">
            <DepartmentInfo department={department} />
            {chain(conferencesByMonthYear)
                .map((conferences, monthYearString) => (
                    <React.Fragment key={monthYearString}>
                        <div className="conferences-agenda-month-year">{monthYearString}</div>
                        {conferences.map((c, i) => (
                            <Conference key={i} conference={c} department={department} />
                        ))}
                    </React.Fragment>
                ))
                .value()}
        </div>
    );
};

export default ConferencesList;
