import { message } from "antd";

import { GET_CONFERENCES_SUCCESS } from "./actionTypes";
import { getConferences } from "../../api/conferences";

export const loadConferences = departmentId => async dispatch => {
    try {
        const conferences = await getConferences(departmentId);
        dispatch({
            type: GET_CONFERENCES_SUCCESS,
            payload: conferences,
        });
    } catch (e) {
        message.error("Impossible de charger les conférences. Veuillez réessayer plus tard.");
    }
};
